import styled from "styled-components";
import { useRef, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { motion as motion3d } from "framer-motion-3d";
import { useGLTF, Environment } from "@react-three/drei";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { EffectComposer, ToneMapping } from "@react-three/postprocessing";
import * as THREE from "three";
import { useParams, useNavigate } from "react-router-dom";
import NotFound from "./NotFound";
import phoneGLB from "../assets/gltf/phone.glb";
import anime from "animejs";
import ExternalLinkRive from "../assets/rive/externalLink.riv";
import { useRive } from "rive-react";
import { Link } from "react-router-dom";
import arrowRight from "../assets/images/svg/arrowRight.svg";

const Hero = styled(motion.div)`
	width: 100vw;
	min-height: 100vh;
	position: relative;
	left: 0;
	top: 0;
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
	gap: 50px;

	video {
		width: 70vw;
		height: 500px;
		object-fit: cover;
		position: absolute;
		z-index: -2;
		//filter: brightness(0.65);
		border-radius: 0 0 15px 15px;

		@media (max-width: 968px) {
			width: 100vw;
			position: static;
		}
	}
	picture,
	img {
		width: 70vw;
		height: 500px;
		object-fit: cover;
		position: absolute;
		z-index: -2;
		//filter: brightness(0.65);
		border-radius: 0 0 15px 15px;

		@media (max-width: 968px) {
			width: 100vw;
			position: static;
		}
	}

	.infos {
		position: absolute;
		top: 550px;
		width: 70vw;
		//max-width: 1200px;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: flex-start;
		gap: 40px;

		@media (max-width: 668px) {
			width: 90vw;
			position: static;
			align-items: center;
		}

		.row {
			width: 100%;
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			align-items: center;
			gap: 20px 40px;

			// first row
			&:first-child {
				@media (max-width: 668px) {
					flex-flow: column;
				}
			}

			@media (max-width: 668px) {
				justify-content: center;
				text-align: center;
			}

			h1 {
				flex: 1;
				font-family: "Ilisarniq";
				font-style: normal;
				font-weight: 900;
				font-size: 64px;
				text-align: left;
				color: #ffffff;
				white-space: pre-wrap;
				text-transform: uppercase;
			}

			h2 {
				flex: 1;
				font-family: "Raleway";
				font-style: normal;
				font-weight: 100;
				font-size: 28px;
				line-height: 38px;
				font-feature-settings: "pnum" on, "lnum" on;
				color: #ffffff;
			}

			a {
				cursor: pointer;
				position: relative;

				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				width: 54px;
				height: 54px;
				transition: none;
			}

			span {
				font-family: "Raleway";
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 150%;
				letter-spacing: 0.085em;
				text-transform: uppercase;
				font-feature-settings: "pnum" on, "lnum" on;
				color: #dddddd;

				b {
					font-weight: 700;
					margin-right: 10px;
				}
			}

			.separator {
				width: 6px;
				height: 30px;
				transform: skewX(-20deg);
				background: #ffffff;
			}

			.tag {
				font-family: "Raleway";
				font-style: normal;

				font-size: 18px;
				font-feature-settings: "pnum" on, "lnum" on;
				padding: 10px 20px;
				text-transform: none;

				&.stack {
					color: #ffffff;
					background: #363636;
					font-weight: 400;
				}

				&.software {
					color: #363636;
					background: #dddddd;
					font-weight: 600;
				}
			}
		}
	}
`;

const Mockups = styled(motion.div)`
	width: 100vw;
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
	position: relative;

	canvas {
		width: 100vw !important;
	}

	.nav {
		position: absolute;
		bottom: 30px;
		width: 120px;
		height: 50px;
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		align-items: center;

		img {
			height: 50px;
			width: 50px;
			opacity: 0.25;
			transition: opacity 0.15s ease;
			cursor: pointer;

			&:hover {
				opacity: 0.6;
			}

			&.selected {
				opacity: 1 !important;
			}
		}
	}
`;

const ScreenMaterial = (props) => {
	const [video] = useState(() =>
		Object.assign(document.createElement("video"), {
			src: props.video,
			crossOrigin: "Anonymous",
			loop: true,
			muted: true,
		})
	);
	useEffect(() => void video.play(), [video]);

	return (
		<meshPhysicalMaterial
			color="#ffffff"
			emmissive="#ffffff"
			clearcoat={0.2}
			clearcoatRoughness={0.08}
		>
			<videoTexture
				attach="map"
				args={[video]}
				loop={true}
				flipY={false}
				encoding={THREE.sRGBEncoding}
			/>
		</meshPhysicalMaterial>
	);
};

const PhoneMockup = (props) => {
	const { nodes } = useGLTF(phoneGLB);
	const { viewport } = useThree();

	const ref1 = useRef();
	const ref2 = useRef();
	useFrame(({ mouse }) => {
		const x = (mouse.x * viewport.width) / 5;
		const y = (mouse.y * viewport.height) / 5;
		ref1.current.position.lerp(
			new THREE.Vector3(1.5, -y / 6, -x / 6 - 0.5),
			0.05
		);
		ref2.current.position.lerp(
			new THREE.Vector3(2.5, y / 6, x / 6 + 0.5),
			0.025
		);

		const vec = new THREE.Vector3(
			ref1.current.rotation.x,
			ref1.current.rotation.y,
			ref1.current.rotation.z
		);
		const vec2 = vec.lerp(
			new THREE.Vector3(Math.PI, -x / 4, y / 8 + Math.PI),
			0.05
		);
		ref1.current.rotation.setFromVector3(vec2);
	});

	return (
		<>
			<motion3d.group>
				<motion3d.group
					position={[0, 0, 0]}
					rotation={[Math.PI, Math.PI, Math.PI]}
					ref={ref1}
				>
					<motion3d.mesh geometry={nodes.Cube001.geometry}>
						<meshPhysicalMaterial
							color="#000000"
							clearcoat={1}
							clearcoatRoughness={0}
						/>
					</motion3d.mesh>
					<motion3d.mesh geometry={nodes.Cube001_1.geometry}>
						<ScreenMaterial
							video={props.projectData.content.phoneVideo}
						/>
					</motion3d.mesh>
				</motion3d.group>
				<motion3d.group
					position={[2, -0.3, 1]}
					rotation={[Math.PI * 1.05, Math.PI, Math.PI]}
					ref={ref2}
				>
					<motion3d.mesh geometry={nodes.Cube001.geometry}>
						<meshPhysicalMaterial
							color="#000000"
							clearcoat={1}
							clearcoatRoughness={0}
						/>
					</motion3d.mesh>
				</motion3d.group>
			</motion3d.group>
		</>
	);
};

const Mockups3D = (props) => {
	return (
		<>
			<Mockups>
				<AnimatePresence mode="wait">
					<motion.div
						className="phoneCanvas"
						key="phoneCanvas"
						style={{
							width: "100vw",
							height: "1000px",
						}}
						initial={{ x: "100vw" }}
						animate={{ x: 0 }}
						exit={{
							x: "-100vw",
							transition: { duration: 0.3 },
						}}
						transition={{ duration: 0.8, type: "spring" }}
					>
						<Canvas
							shadows
							dpr={[1, 2]}
							camera={{
								zoom: 1,
								position: [-3, 0, 0],
								fov: 70,
							}}
							style={{ width: "100vw", height: "100%" }}
						>
							<EffectComposer>
								<ToneMapping middleGrey={20} />
							</EffectComposer>
							<Environment
								background={false} // can be true, false or "only" (which only sets the background) (default: false)
								files={[
									"nx.png",
									"nx.png",
									"py.png",
									"py.png",
									"nx.png",
									"nx.png",
								]}
								path="/cube/"
								preset={null}
								scene={undefined} // adds the ability to pass a custom THREE.Scene, can also be a ref
								encoding={THREE.sRGBEncoding} // adds the ability to pass a custom THREE.TextureEncoding (default: THREE.sRGBEncoding for an array of files and THREE.LinearEncoding for a single texture)
							/>

							<PhoneMockup
								projectData={props.projectData}
							></PhoneMockup>
						</Canvas>
					</motion.div>
				</AnimatePresence>
			</Mockups>
		</>
	);
};

const Content = styled(motion.div)`
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;

	@media (max-width: 968px) {
		margin-top: 100px;
	}

	.phoneCanvas {
		height: 100vh;
	}

	.gallery {
		width: 100vw;
		display: flex;
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
		gap: 50px;

		img {
			width: 70vw;
			object-fit: cover;
			border-radius: 15px;

			@media (max-width: 668px) {
				width: 100vw;
			}
		}
	}
`;

const CrossLinker = styled(motion.div)`
	width: 70vw;
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;
	margin-top: 150px;
	margin-bottom: 100px;
	gap: 50px;

	@media (max-width: 668px) {
		flex-flow: column-reverse;
	}

	div {
		position: relative;
		width: 100%;
		aspect-ratio: 20/9;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		border-radius: 15px;

		@media (max-width: 668px) {
			aspect-ratio: unset;
		}

		picture,
		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			filter: grayscale(100%) brightness(0.6);
			transition: filter 0.5s ease, transform 0.5s ease;

			@media (max-width: 668px) {
				display: none;
			}
		}

		&:hover {
			picture,
			img {
				transform: scale(1.05);
				filter: grayscale(0%) brightness(0.6);
			}
		}

		a {
			cursor: pointer;
			border: 2px solid #ffffff;
			border-radius: 50px;
			padding: 20px 30px;
			position: relative;

			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			width: 300px;
			transition: none;

			img {
				position: relative;
				filter: none !important;
				width: 24px;
				height: 24px;
				object-fit: contain;

				&.rotate {
					transform: rotate(180deg);
				}
			}

			.initial,
			.hover {
				pointer-events: none;
				display: flex;
				flex-flow: row;
				justify-content: center;
				align-items: center;
				position: absolute;
				gap: 10px;
				width: 100%;
				height: 100%;
				transition: all 0.3s ease;

				span {
					font-family: "Raleway";
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 19px;
					display: flex;
					align-items: center;
					text-align: center;
					letter-spacing: 2px;
					text-transform: uppercase;
					transform: translateY(1px);
				}
			}

			&:hover {
				background: white;
				transition: 0.2s background 0.2s ease;

				.initial {
					opacity: 0;
				}

				.hover {
					transform: none;
				}
			}

			.hover {
				background: white;
				border-radius: 50px;
				transform: translateX(100%);

				span {
					color: #1c1c1c;
				}

				img {
					filter: brightness(0);
				}
			}
		}
	}
`;

export default function Project() {
	const params = useParams();
	const navigate = useNavigate();
	const [pageNotFound, setPageNotFound] = useState(false);
	const [projectData, setProjectData] = useState(
		JSON.parse(sessionStorage.getItem("projectData")) || null
	);
	const [loadMockup, setLoadMockup] = useState(false);
	// make loadMockup true 1 second after page load
	useEffect(() => {
		setTimeout(() => {
			setLoadMockup(true);
		}, 1000);
	}, []);

	const { RiveComponent } = useRive({
		src: ExternalLinkRive,
		stateMachines: "State Machine 1",
		autoplay: true,
	});

	useEffect(() => {
		// scroll to top
		window.scrollTo(0, 0);

		sessionStorage.removeItem("projectData");
		sessionStorage.removeItem("videoPortal");

		// check if projectData.id exists
		if (!projectData) {
			fetch(window.dataFetchUrl + "project/" + params.slug, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			})
				.then(function (response) {
					if (response.status === 404) {
						setPageNotFound(true);
						return;
					}
					return response.json();
				})
				.then(function (myJson) {
					setProjectData(myJson);
				});
		} else {
			if (document.getElementById("videoPortal")) {
				document.getElementById("videoPortal").style.position =
					"absolute";

				var video = document
					.getElementById("videoPortal")
					.getElementsByTagName("video")[0];

				var finalPosition = {
					top: 0,
				};

				anime({
					targets: video,
					top: finalPosition.top,
					height: 500,
					duration: 500,
					easing: "easeOutExpo",
					filter: "brightness(1)",
				});
			}
		}

		return () => {
			// delete videoPortal from dom
			if (document.getElementById("videoPortal")) {
				document.getElementById("videoPortal").style.opacity = 0;
				setTimeout(() => {
					document.getElementById("videoPortal").remove();
				}, 300);
			}
		};
	}, [navigate, params.slug, projectData]);

	if (pageNotFound) {
		return <NotFound />;
	}

	return (
		<>
			<Hero exit={{ opacity: 0, transition: { duration: 0.5 } }}>
				{projectData ? (
					projectData.video ? (
						<motion.video
							muted
							loop
							autoPlay
							controls={false}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 1 }}
						>
							<source
								src={projectData.video[1]}
								type="video/webm"
							/>
							<source
								src={projectData.video[0]}
								type="video/mp4"
							/>
							Sorry, your browser doesn't support embedded videos.
						</motion.video>
					) : (
						<motion.picture
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 1 }}
						>
							<source srcSet={projectData.thumb[1]}></source>
							<source srcSet={projectData.thumb[0]}></source>
							<img
								src={projectData.thumb[1]}
								alt={projectData.slug + " thumbnail"}
							/>
						</motion.picture>
					)
				) : null}
				<motion.div className="infos">
					<div className="row">
						<motion.h1
							initial={{ opacity: 0 }}
							animate={{
								opacity: 1,
								transition: {
									delay: 0.25,
									duration: 0.5,
								},
							}}
						>
							{projectData && projectData.name}
						</motion.h1>
						{projectData && (
							<motion.a
								href={projectData.url}
								target="_blank"
								rel="noreferrer noopener"
								initial={{ opacity: 0, x: 50 }}
								animate={{
									opacity: 1,
									x: 0,
									transition: {
										delay: 0.5,
										duration: 0.5,
									},
								}}
							>
								{<RiveComponent />}
							</motion.a>
						)}
					</div>
					{projectData && (
						<motion.div
							className="row"
							initial={{ opacity: 0 }}
							animate={{
								opacity: 1,
								transition: {
									delay: 0.5,
									duration: 0.5,
								},
							}}
						>
							<span>{projectData.slogan}</span>
							<span
								className="separator"
								style={{ backgroundColor: projectData.color }}
							></span>
							<span>
								<b>année</b>
								{projectData.year}
							</span>
							<span
								className="separator"
								style={{ backgroundColor: projectData.color }}
							></span>
							<span>
								<b>mission</b>
								{projectData.mission}
							</span>
						</motion.div>
					)}
					<motion.div
						className="row"
						initial={{ opacity: 0 }}
						animate={{
							opacity: 1,
							transition: {
								delay: 0.75,
								duration: 0.5,
							},
						}}
					>
						{projectData &&
							projectData.content.tags.stacks.map(
								(tag, index) => (
									<span className="tag stack" key={index}>
										{tag}
									</span>
								)
							)}
						{projectData &&
							projectData.content.tags.softwares.map(
								(tag, index) => (
									<span className="tag software" key={index}>
										{tag}
									</span>
								)
							)}
					</motion.div>
				</motion.div>
			</Hero>
			<Content exit={{ opacity: 0 }}>
				{window.innerWidth > 668 &&
					projectData &&
					loadMockup &&
					projectData.content.phoneVideo && (
						<Mockups3D projectData={projectData} />
					)}
				<div className="gallery">
					{projectData &&
						projectData.content.images &&
						projectData.content.images.map((image, index) => (
							<motion.picture
								key={index}
								initial={{ opacity: 0 }}
								whileInView={{
									opacity: 1,
									transition: {
										duration: 0.5,
									},
								}}
								exit={{
									opacity: 0,
									transition: {
										duration: 0.5,
									},
								}}
							>
								<source
									srcSet={image[1]}
									type="image/webp"
								></source>
								<source
									srcSet={image[0]}
									type="image/jpg"
								></source>
								<img
									src={image[0]}
									alt={
										projectData.slug +
										" image gallerie " +
										index
									}
								/>
							</motion.picture>
						))}
				</div>
			</Content>
			<CrossLinker exit={{ opacity: 0 }}>
				{projectData && (
					<>
						<motion.div>
							<picture>
								<source
									srcSet={
										projectData.previousProject.thumb[1]
									}
								></source>
								<source
									srcSet={
										projectData.previousProject.thumb[0]
									}
								></source>
								<img
									src={projectData.previousProject.thumb[0]}
									alt={
										projectData.previousProject.slug +
										" thumbnail"
									}
								/>
							</picture>
							<Link
								to={
									"/project/" +
									projectData.previousProject.slug
								}
							>
								<motion.div className="initial">
									<motion.img
										src={arrowRight}
										className="rotate"
									/>
									<motion.span>Projet précédent</motion.span>
								</motion.div>
								<motion.div className="hover">
									<motion.span>Projet précédent</motion.span>
								</motion.div>
							</Link>
						</motion.div>
						<motion.div>
							<picture>
								<source
									srcSet={projectData.nextProject.thumb[1]}
								></source>
								<source
									srcSet={projectData.nextProject.thumb[0]}
								></source>
								<img
									src={projectData.nextProject.thumb[0]}
									alt={
										projectData.nextProject.slug +
										" thumbnail"
									}
								/>
							</picture>
							<Link
								to={"/project/" + projectData.nextProject.slug}
							>
								<motion.div className="initial">
									<motion.span>Projet suivant</motion.span>
									<motion.img src={arrowRight} />
								</motion.div>
								<motion.div className="hover">
									<motion.span>Projet suivant</motion.span>
								</motion.div>
							</Link>
						</motion.div>
					</>
				)}
			</CrossLinker>
		</>
	);
}
