import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import styled from "styled-components";
import arrowRight from "../assets/images/svg/arrowRight.svg";
import webIcon from "../assets/images/svg/web_icon.svg";
import mobileIcon from "../assets/images/svg/mobile_icon.svg";

const MasonryContainer = styled(motion.div)`
	display: flex;
	flex-flow: column;
	width: 100%;
	min-height: 100vh;
	justify-content: flex-start;
	align-items: center;
	padding-top: 150px;
	padding-bottom: 100px;

	.filters {
		top: 0;
		display: flex;
		flex-flow: row;
		justify-content: flex-start;
		width: 80vw;
		max-width: 1300px;
		height: auto;
		gap: 10px;
		background: rgba(21, 21, 21, 0.75);
		backdrop-filter: blur(20px);
		padding: 20px;
		flex-wrap: wrap;

		@media (max-width: 668px) {
			width: 100vw;
		}

		span {
			cursor: pointer;
			font-family: "Raleway";
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 14px;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 3px;
			text-transform: uppercase;
			font-feature-settings: "pnum" on, "lnum" on;
			opacity: 0.6;

			border: 1px solid transparent;
			padding: 10px 10px 8px 14px;
			border-radius: 25px;
			transition: all 0.15s ease;

			&.selected {
				font-weight: 500;
				border-color: white;
				opacity: 1;
				&:hover {
					border-color: white;
					opacity: 1;
				}
			}

			&:hover {
				border-color: rgba(255, 255, 255, 0.5);
				opacity: 1;
			}
		}
	}

	.my-masonry-grid {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: flex-start;
		width: 80vw;
		max-width: 1300px;

		@media (max-width: 668px) {
			width: 100vw;
		}

		div {
			padding: 25px;
			display: flex;
			flex-flow: column;
			transition: filter 0s linear;
			width: 45%;
			height: 400px;

			&:nth-child(4n + 2),
			&:nth-child(4n + 3) {
				width: 55%;
			}

			@media (max-width: 668px) {
				width: 100% !important;
				height: 250px;
			}

			div {
				position: relative;
				display: flex;
				flex-flow: column;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				width: 100%;
				height: 100%;
				border-radius: 15px;
				box-shadow: 0px 0px 100px rgba(0, 0, 0, 0);
				transition: box-shadow 1s ease;

				video {
					position: absolute;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					opacity: 0;
					z-index: 1;
					transition: opacity 0.3s ease, width 0.3s ease;
				}

				img,
				picture {
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					pointer-events: none;
					transition: width 0.3s ease, opacity 0.3s ease,
						height 0.3s ease;
				}

				.overlay {
					position: absolute;
					z-index: 2;
					width: 60%;
					height: 100%;
					left: 100%;
					background: rgba(16, 16, 16);
					border-radius: 0;
					pointer-events: none;
					display: flex;
					flex-flow: column;
					justify-content: center;
					align-items: center;
					gap: 20px;
					transition: transform 0.3s ease, box-shadow 0.3s ease;
					box-shadow: 0px 0px 100px rgba(0, 0, 0, 0);

					h3 {
						font-family: "Ilisarniq";
						font-style: normal;
						font-weight: 900;
						font-size: 34px;
						text-transform: uppercase;
						line-height: 36px;
						text-align: center;
						font-feature-settings: "pnum" on, "lnum" on;
						color: #ffffff;

						@media (max-width: 668px) {
							font-size: 24px;
							line-height: 26px;
						}
					}

					.icon {
						width: 36px;
						height: 36px;
						object-fit: contain;
						position: relative;
						opacity: 1 !important;
					}

					a {
						pointer-events: all;
						cursor: pointer;
						border: 2px solid #ffffff;
						border-radius: 50px;
						padding: 16px 0;
						position: relative;

						display: flex;
						justify-content: center;
						align-items: center;
						overflow: hidden;
						width: 90%;
						max-width: 200px;
						transition: none;

						.initial,
						.hover {
							pointer-events: none;
							display: flex;
							flex-flow: row;
							justify-content: center;
							align-items: center;
							position: absolute;
							gap: 10px;
							width: 100%;
							height: 100%;
							transition: all 0.3s ease;

							span {
								font-family: "Raleway";
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 14px;
								display: flex;
								align-items: center;
								text-align: center;
								letter-spacing: 2px;
								text-transform: uppercase;
								transform: translateY(1px);
							}

							img {
								position: relative;
								width: auto;
								height: auto;
							}
						}

						&:hover {
							background: white;
							transition: 0.2s background 0.2s ease;

							.initial {
								opacity: 0;
							}

							.hover {
								transform: none;
							}
						}

						.hover {
							background: white;
							border-radius: 50px;
							transform: translateX(100%);

							span {
								color: #1c1c1c;
							}

							img {
								filter: brightness(0);
							}
						}
					}
				}

				&:hover {
					box-shadow: 0px 0px 100px rgba(0, 0, 0, 1);

					video {
						opacity: 1;
						width: 40%;
					}

					img {
						width: 40%;
						height: 120%;

						@media (max-width: 668px) {
							opacity: 0;
						}
					}

					.overlay {
						transform: translateX(-100%);
						box-shadow: 0px 0px 100px rgba(0, 0, 0, 1);

						img {
							opacity: 1 !important;
						}
					}
				}
			}
		}
	}
`;

const renderSwitchIcon = (type) => {
	switch (type) {
		case "web":
			return <img className="icon" src={webIcon} alt="web icon" />;
		case "mobile":
			return <img className="icon" src={mobileIcon} alt="mobile icon" />;
		default:
			return <img className="icon" src={webIcon} alt="web icon" />;
	}
};

const Masonry = (props) => {
	return (
		<motion.div
			className="my-masonry-grid"
			exit={{ opacity: 0 }}
			transition={{
				duration: 0.3,
			}}
		>
			<AnimatePresence mode="wait">
				{props.children.map((project, i) => (
					<motion.div
						key={"masonryItem_" + Date.now() + "_" + i}
						initial={{ opacity: 0 }}
						animate={{
							opacity: 1,
							transition: {
								delay: Math.random() / 2,
								duration: Math.random() + 0.2,
							},
						}}
						exit={{ opacity: 0, transition: { duration: 0.1 } }}
						style={project.style}
						onMouseEnter={(e) => {
							var video = e.target.querySelector("video");
							if (video) {
								video.currentTime = 0;
								video.play();
							}
						}}
						onMouseLeave={(e) => {
							var video = e.target.querySelector("video");
							if (video) {
								video.pause();
							}
						}}
					>
						<div>
							<picture>
								<source srcSet={project.thumb[1]}></source>
								<source srcSet={project.thumb[1]}></source>
								<img
									src={project.thumb[0]}
									alt={project.slug + " thumbnail"}
								/>
							</picture>
							{window.innerWidth > 668 && project.video && (
								<video muted loop>
									<source
										src={project.video[1]}
										type="video/webm"
									/>
									<source
										src={project.video[1]}
										type="video/mp4"
									/>
									Sorry, your browser doesn't support embedded
									videos.
								</video>
							)}
							<div className="overlay">
								{renderSwitchIcon(project.type)}
								<h3>{project.name}</h3>
								<Link to={"/project/" + project.slug}>
									<motion.div className="initial">
										<motion.span>Voir</motion.span>
										<motion.img src={arrowRight} />
									</motion.div>
									<motion.div className="hover">
										<motion.span>Voir</motion.span>
									</motion.div>
								</Link>
							</div>
						</div>
					</motion.div>
				))}
			</AnimatePresence>
		</motion.div>
	);
};

const Filters = (props) => {
	return (
		<motion.div
			className="filters"
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.5 }}
		>
			{props.children.map((filter, index) => {
				return (
					<span
						className={props.current === index ? "selected" : ""}
						key={index}
						onClick={() => props.setCurrentFilter(index, filter)}
					>
						{filter}
					</span>
				);
			})}
		</motion.div>
	);
};

export default function Projects() {
	const [data, setData] = useState(null);
	const [filteredItems, setFilteredItems] = useState([]);
	const [filters, setFilters] = useState([]);
	const [currentFilter, setCurrentFilter] = useState([]);

	useEffect(() => {
		window.scroll({
			top: 0,
			behavior: "auto",
		});

		fetch(window.dataFetchUrl + "data", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (myJson) {
				setData(myJson);

				var _filters = ["Tous"];

				setFilteredItems(
					myJson.projects.map((item, i) => {
						item.filters.map((filter) =>
							_filters.indexOf(filter) === -1
								? _filters.push(filter)
								: null
						);
						return item;
					})
				);

				setFilters([..._filters]);

				setCurrentFilter(0, "tous");
			});
	}, []);

	const updateCurrentFilter = (index, slug) => {
		setCurrentFilter(index);
		window.scroll({
			top: 0,
			behavior: "auto",
		});

		var _filteredItems = [];

		if (index === 0) {
			data.projects.map((item, i) => _filteredItems.push(item));
		} else {
			data.projects.map((item, i) =>
				item.filters.map((filter) =>
					slug === filter ? _filteredItems.push(item) : null
				)
			);
		}

		setFilteredItems(
			_filteredItems.map((item, i) => {
				return item;
				/*return (
					<Card key={Date.now() + "_" + i} item={item} index={i} />
				);*/
			})
		);
	};

	return (
		<>
			<MasonryContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
				<Filters
					current={currentFilter}
					setCurrentFilter={updateCurrentFilter}
				>
					{filters}
				</Filters>
				{filteredItems && <Masonry>{filteredItems}</Masonry>}
			</MasonryContainer>
		</>
	);
}
