import React, { useEffect, useState } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
} from "react-router-dom";
import ReactDOM from "react-dom/client";
import { AnimatePresence } from "framer-motion";
import "destyle.css";
import { GlobalStyles } from "./GlobalStyles";
import Navbar from "./components/navbar";
import Home from "./pages/Home";
import Project from "./pages/Project";
import Projects from "./pages/Projects";
import NotFound from "./pages/NotFound";
import Footer from "./components/footer";

// create a global variable
//window.dataFetchUrl = "/api/";
window.dataFetchUrl = "https://avangarde-studio.com/api/";

const setFavicon = () => {
	// create favicon element
	const favicon = document.createElement("link");
	favicon.rel = "icon";
	favicon.type = "image/png";
	document.head.appendChild(favicon);
	if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
		favicon.href = "/favicon-dark.png";
	} else {
		favicon.href = "/favicon-light.png";
	}

	window
		.matchMedia("(prefers-color-scheme: dark)")
		.addEventListener("change", (e) => {
			if (e.matches) {
				favicon.href = "/favicon-dark.png";
			} else {
				favicon.href = "/favicon-light.png";
			}
		});
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Router>
		<App />
	</Router>
);

const usePrevious = (value) => {
	const ref = React.useRef();
	React.useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

const useLocationChange = (action) => {
	const location = useLocation();
	const prevLocation = usePrevious(location);
	React.useEffect(() => {
		if (location !== prevLocation) {
			action(location, prevLocation);
		}
	}, [action, location, prevLocation]);
};

function App() {
	const location = useLocation();
	const [cachedScroll, setCachedScroll] = useState([]);

	useEffect(() => {
		setFavicon();
		// body style
		document.body.style.transition =
			"filter 0.5s ease, background 0.5s ease, color 0.5s ease";

		window.history.scrollRestoration = "manual";
	}, []);

	window.addEventListener("popstate", (e) => {
		if (cachedScroll.length <= 0) return;
		const scrollY = cachedScroll.pop();
		setTimeout(() => {
			window.scrollTo(0, scrollY);
		}, 500);

		// animate #videoPortal opacity to 0
		const videoPortal = document.getElementById("videoPortal");
		if (videoPortal === null) return;
		videoPortal.style.opacity = 0;
	});

	useLocationChange((location, prevLocation) => {
		// check for undefined lcoation
		if (prevLocation === undefined) return;
		setCachedScroll([...cachedScroll, window.scrollY]);
	});

	return (
		<>
			<GlobalStyles />
			<Navbar />
			<AnimatePresence mode="wait">
				<Routes location={location} key={location.pathname}>
					<Route path="/" element={<Home />} />
					<Route path="/projects" element={<Projects />} />
					<Route path="/project/:slug" element={<Project />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</AnimatePresence>
			<Footer />
		</>
	);
}
