import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import ProjectsGallery from "../components/ProjectsGallery";
import Home3DScene from "../components/Home3DScene";
import arrowRight from "../assets/images/svg/arrowRight.svg";
import axios from "axios";

const HomeSection = styled(motion.section)`
	h1 {
		font-family: "Raleway";
		font-style: normal;
		font-weight: 300;
		font-size: 40px;
		line-height: 47px;
		color: #4f4f4f;
		margin-left: 12px;
		width: 100%;
		padding-right: 50px;

		@media (max-width: 568px) {
			margin-left: 5px;
		}

		b {
			color: #aeaeae;
		}
	}

	h2 {
		font-family: "Ilisarniq";
		font-style: normal;
		font-weight: 900;
		font-size: 130px;
		line-height: 130px;
		letter-spacing: 0;
		color: #ffffff;
		display: flex;
		flex-flow: column;
		position: relative;

		// if device width is less than 768px
		@media (max-width: 568px) {
			font-size: 80px;
			line-height: 80px;
		}

		div {
			color: #ffffff;
			display: flex;
			flex-flow: row;
		}
	}
`;

const ProjectsSection = styled(motion.section)`
	position: relative;
	display: flex;
	flex-flow: column;
	justify-content: center;
`;

const SkillsSection = styled(motion.section)`
	margin-top: 15vw;

	@media (max-width: 568px) {
		margin-top: 300px;
	}

	> div {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		gap: 100px;

		@media (max-width: 1268px) {
			gap: 50px;
		}
		@media (max-width: 968px) {
			gap: 200px;
			flex-flow: column;
		}

		.underscoreFlash {
			display: inline-block;
			height: 80px;
			border-right: 10px solid white;
			transform: translate(40px, 8px) rotate(25deg);
			animation: underscoreFlash 0.5s infinite alternate-reverse steps(2);

			@keyframes underscoreFlash {
				from {
					visibility: hidden;
				}
				to {
					visibility: visible;
				}
			}
		}

		.col-left {
			display: flex;
			flex-flow: column;
			justify-content: flex-start;
			align-items: flex-start;
			width: 100%;
			gap: 50px;

			h1 {
				font-family: "Ilisarniq";
				font-style: normal;
				font-weight: 900;
				font-size: 96px;
				line-height: 130px;
				color: #ffffff;
				width: 100%;
				text-align: left;

				@media (max-width: 968px) {
					font-size: 76px;
					line-height: 110px;
				}
			}

			p {
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				font-family: "Raleway";
				font-style: normal;
				font-weight: 300;
				font-size: 22px;
				line-height: 160%;
				letter-spacing: 2px;
				color: #969696;
				text-align: left;
				width: 1000px;
				white-space: pre-line;

				@media (max-width: 1400px) {
					width: 100%;
				}
				@media (max-width: 568px) {
					font-size: 18px;
					line-height: 140%;
				}

				&:after {
					content: "";
					z-index: -1;
					position: absolute;
					background: rgb(25, 25, 25);
					width: 50px;
					height: 300px;
					right: 0;
					transform: skewX(-25deg);
				}
			}

			a {
				cursor: pointer;
				border: 2px solid #ffffff;
				border-radius: 50px;
				padding: 20px 30px;
				position: relative;

				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				width: 300px;
				transition: none;

				.initial,
				.hover {
					pointer-events: none;
					display: flex;
					flex-flow: row;
					justify-content: center;
					align-items: center;
					position: absolute;
					gap: 10px;
					width: 100%;
					height: 100%;
					transition: all 0.3s ease;

					span {
						font-family: "Raleway";
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 19px;
						display: flex;
						align-items: center;
						text-align: center;
						letter-spacing: 2px;
						text-transform: uppercase;
						transform: translateY(1px);
					}
				}

				&:hover {
					background: white;
					transition: 0.2s background 0.2s ease;

					.initial {
						opacity: 0;
					}

					.hover {
						transform: none;
					}
				}

				.hover {
					background: white;
					border-radius: 50px;
					transform: translateX(100%);

					span {
						color: #1c1c1c;
					}

					img {
						filter: brightness(0);
					}
				}
			}
		}

		.col-right {
			width: 100%;
			display: flex;
			flex-flow: column;
			gap: 50px;

			.skill {
				display: flex;
				flex-flow: column;
				justify-content: center;
				align-items: start;
				gap: 15px;
				.name {
					font-family: "Raleway";
					font-style: normal;
					font-weight: 300;
					font-size: 25px;
					line-height: 38px;
					color: #ffffff;
				}

				.value {
					width: 100%;
					height: 8px;
					background: #313131;
					border-radius: 12px;
					position: relative;

					.fill {
						position: absolute;
						background: white;
						width: 0;
						height: 100%;
						border-radius: 12px;
						box-shadow: -50px 0px 150px;
					}
				}
			}
		}
	}
`;

const ContactSection = styled(motion.section)`
	height: auto;
	min-height: auto;
	margin-top: 40vh;
	padding-bottom: 30vh;
	position: relative;

	> div {
		display: flex;
		flex-flow: column;
		justify-content: start;
		align-items: start;
		gap: 20px;

		h1 {
			font-family: "Ilisarniq";
			font-style: normal;
			font-weight: 900;
			font-size: 96px;
			line-height: 100%;
			color: #ffffff;
			width: 100%;
			text-align: left;
			margin-bottom: 60px;
			text-transform: uppercase;

			@media (max-width: 968px) {
				font-size: 76px;
			}
			@media (max-width: 568px) {
				font-size: 46px;
			}
		}

		.form {
			width: 100%;
			max-width: 600px;
			position: relative;
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			gap: 30px;

			.step {
				width: 100%;
				display: flex;
				flex-flow: column;
				gap: 20px;

				span {
					font-family: "Raleway";
					font-style: normal;
					font-weight: 400;
					font-size: 32px;
					line-height: 38px;
					/* identical to box height */

					color: #ffffff;
				}

				input,
				textarea {
					border-bottom: 1px solid #6d6d6d;
					padding: 10px 0;

					font-family: "Raleway";
					font-weight: 400;
					font-size: 20px;
					line-height: 23px;
					display: flex;
					letter-spacing: 0.1em;
					align-items: center;

					color: #ffffff;
					outline: 0;

					&::placeholder {
						font-style: italic;
						color: #616161;
					}
				}

				textarea {
					resize: none;
					height: 180px;
				}
			}

			.navigation {
				display: flex;
				flex-flow: row;
				justify-content: end;
				align-items: center;
				width: 100%;
				gap: 50px;

				@media (max-width: 568px) {
					flex-flow: column;
					gap: 10px;
				}

				.info {
					font-family: "Raleway";
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 19px;
					text-align: center;
					font-feature-settings: "pnum" on, "lnum" on;
					color: #ffffff;
				}

				.next {
					position: relative;
					border: 2px solid #ffffff;
					border-radius: 50px;
					padding: 30px 30px;

					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					width: 250px;

					.initial,
					.hover {
						display: flex;
						flex-flow: row;
						justify-content: center;
						align-items: center;
						position: absolute;
						gap: 10px;
						width: 100%;
						height: 100%;
						transition: all 0.3s ease;

						span {
							font-family: "Raleway";
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 19px;
							display: flex;
							align-items: center;
							text-align: center;
							letter-spacing: 2px;
							text-transform: uppercase;
							transform: translateY(1px);
						}
					}

					&:hover {
						.initial {
							opacity: 0;
						}
						.hover {
							transform: none;
						}
					}

					.hover {
						background: white;
						border-radius: 50px;
						transform: translateX(100%);

						span {
							color: #1c1c1c;
						}

						img {
							filter: brightness(0);
						}
					}
				}
			}
		}
	}
`;

var H2Content = "DEV. FRONT END".split(" ");

const ContactForm = () => {
	const [step, setStep] = useState(0);

	const nameInputRef = useRef(null);
	const mailInputRef = useRef(null);
	const messageInputRef = useRef(null);

	const [nameInputError, setNameInputError] = useState(true);
	const [mailInputError, setMailInputError] = useState(true);
	const [messageInputError, setMessageInputError] = useState(true);

	const [nameInputValue, setNameInputValue] = useState(null);
	const [mailInputValue, setMailInputValue] = useState(null);
	const [messageInputValue, setMessageInputValue] = useState(null);

	useEffect(() => {
		if (step !== 3) return;
		if (nameInputError || mailInputError || messageInputError) return;
		if (!nameInputValue || !mailInputValue || !messageInputValue) return;

		// post to api with form data
		axios
			.post(window.dataFetchUrl + "contact", {
				name: nameInputValue,
				email: mailInputValue,
				message: messageInputValue,
			})
			.then((res) => {
				console.log(res);
			})
			.catch((err) => {});
	}, [
		step,
		nameInputValue,
		mailInputValue,
		messageInputValue,
		nameInputError,
		mailInputError,
		messageInputError,
	]);

	const ContactFormNavButton = (props) => {
		return (
			<motion.div
				className="navigation"
				initial={{ opacity: 0, y: 20 }}
				whileInView={{
					opacity: 1,
					y: 0,
					transition: {
						type: "spring",
						duration: 0.6,
						damping: 8,
					},
				}}
				transition={{ duration: 0 }}
			>
				<motion.span className="info">{step + 1} sur 3</motion.span>
				<motion.button
					className="next"
					onClick={props.onClick}
					style={{ display: props.show ? "none" : "flex" }}
				>
					<motion.div className="initial">
						<motion.span>{props.text}</motion.span>
						<motion.img src={arrowRight} />
					</motion.div>
					<motion.div className="hover">
						<motion.span>{props.text}</motion.span>
					</motion.div>
				</motion.button>
			</motion.div>
		);
	};

	const ContactFormStepVariants = {
		initial: { opacity: 0, x: 200 },
		animate: {
			opacity: 1,
			x: 0,
			transition: {
				type: "spring",
				duration: 0.6,
				damping: 8,
			},
		},
		exit: { opacity: 0, x: -20 },
		transition: { duration: 1 },
	};

	const CheckEmail = (email) => {
		// don't remember from where i copied this code, but this works.
		let re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (re.test(email)) return true;

		return false;
	};

	return (
		<>
			<motion.form className="form">
				<AnimatePresence mode="wait">
					{
						{
							0: (
								<>
									<motion.div
										key={step}
										className="step"
										variants={ContactFormStepVariants}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										<motion.span>
											Quel est votre nom ?
										</motion.span>
										<motion.input
											ref={nameInputRef}
											type="text"
											placeholder="Ecrire ici ..."
											onChange={(e) => {
												e.target.value
													? setNameInputError(false)
													: setNameInputError(true);
											}}
										/>
									</motion.div>
									<ContactFormNavButton
										text="Suivant"
										onClick={(e) => {
											e.preventDefault();
											if (nameInputError) return;
											if (nameInputRef.current.value) {
												setNameInputValue(
													nameInputRef.current.value
												);
												setStep((prev) => prev + 1);
											}
										}}
										show={nameInputError}
									/>
								</>
							),
							1: (
								<>
									<motion.div
										key={step}
										className="step"
										variants={ContactFormStepVariants}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										<motion.span>
											Quelle est votre adresse e-mail ?
										</motion.span>
										<motion.input
											ref={mailInputRef}
											type="mail"
											placeholder="Ecrire ici ..."
											onChange={(e) => {
												e.target.value &&
												CheckEmail(e.target.value)
													? setMailInputError(false)
													: setMailInputError(true);
											}}
										/>
									</motion.div>
									<ContactFormNavButton
										text="Suivant"
										onClick={(e) => {
											e.preventDefault();
											if (mailInputError) return;
											if (mailInputRef.current.value) {
												setMailInputValue(
													mailInputRef.current.value
												);
												setStep((prev) => prev + 1);
											}
										}}
										show={mailInputError}
									/>
								</>
							),
							2: (
								<>
									<motion.div
										key={step}
										className="step"
										variants={ContactFormStepVariants}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										<motion.span>
											Ecrivez votre message
										</motion.span>
										<motion.textarea
											ref={messageInputRef}
											placeholder="Ecrire ici ..."
											onChange={(e) => {
												e.target.value
													? setMessageInputError(
															false
													  )
													: setMessageInputError(
															true
													  );
											}}
										/>
									</motion.div>
									<ContactFormNavButton
										text="Envoyer"
										onClick={(e) => {
											e.preventDefault();
											if (messageInputError) return;
											if (messageInputRef.current.value) {
												setMessageInputValue(
													messageInputRef.current
														.value
												);
												setStep((prev) => prev + 1);
											}
										}}
										show={messageInputError}
									/>
								</>
							),
							3: (
								<>
									<motion.div
										key={step}
										className="step"
										variants={ContactFormStepVariants}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										<motion.span>
											Merci.
											<br />
											Votre message sera traité dans les
											plus brefs délais.
										</motion.span>
									</motion.div>
								</>
							),
						}[step]
					}
				</AnimatePresence>
			</motion.form>
		</>
	);
};

export default function Home() {
	const [data, setData] = useState(null);

	useEffect(() => {
		fetch(window.dataFetchUrl + "data", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (myJson) {
				setData(myJson);
			});
	}, []);

	return (
		<>
			<HomeSection id="home" exit={{ opacity: 0 }}>
				<motion.div
					style={{
						display: "flex",
						justifyContent: "start",
						alignItems: "center",
					}}
				>
					<motion.div
						style={{
							width: "100%",
							zIndex: 1,
							pointerEvents: "none",
						}}
					>
						<motion.h1
							initial={{
								x: 100,
								opacity: 0,
							}}
							whileInView={{
								x: 0,
								opacity: 1,
								transition: { duration: 1, type: "spring" },
							}}
							transition={{ duration: 0 }}
						>
							Je suis <b>Benjamin</b>
						</motion.h1>
						<motion.h2
							initial={{
								x: 100,
								opacity: 0,
							}}
							whileInView={{
								x: 0,
								opacity: 1,
								transition: { duration: 1, type: "spring" },
							}}
						>
							{window.innerWidth > 568
								? H2Content.map((v, i) => {
										return (
											<motion.div
												key={i}
												initial={{
													x: 200,
													opacity: 0,
													transition: { duration: 0 },
												}}
												whileInView={{
													x: 0,
													opacity: 1,
													transition: {
														type: "spring",
														damping: 6,
														duration: 0.2,
														delay: i / 4 + 0.4,
													},
												}}
												transition={{ duration: 0 }}
											>
												{v.split("").map((d, k) => (
													<motion.span
														key={k}
														initial={{
															x: 200,
															opacity: 0,
															transition: {
																duration: 0,
															},
														}}
														whileInView={{
															x: 0,
															opacity: 1,
															transition: {
																type: "ease",
																duration: 0.16,
																delay:
																	k / 6 + 0.5,
															},
														}}
														transition={{
															duration: 0,
														}}
													>
														{d}
													</motion.span>
												))}
											</motion.div>
										);
								  })
								: H2Content.map((v, i) => {
										return (
											<motion.div
												key={i}
												initial={{
													x: 200,
													opacity: 0,
													transition: { duration: 0 },
												}}
												whileInView={{
													x: 0,
													opacity: 1,
													transition: {
														type: "spring",
														damping: 6,
														duration: 0.2,
														delay: i / 4 + 0.4,
													},
												}}
												transition={{ duration: 0 }}
											>
												{v}
											</motion.div>
										);
								  })}
						</motion.h2>
					</motion.div>
					<motion.div
						style={{ position: "absolute", zIndex: "0" }}
						initial={{ opacity: 0 }}
						whileInView={{ opacity: 1 }}
						transition={{ duration: 0.5 }}
					>
						<Home3DScene />
					</motion.div>
				</motion.div>
			</HomeSection>
			<ProjectsSection>
				{data && <ProjectsGallery projects={data.projects} />}
			</ProjectsSection>
			<SkillsSection id="skills" exit={{ opacity: 0 }}>
				<motion.div>
					<motion.div className="col-left">
						<motion.h1>SKILLS</motion.h1>
						<motion.p>{data?.description}</motion.p>
						<motion.a href="./assets/CV_min.pdf" target="_blank">
							<motion.div className="initial">
								<motion.span>Mon CV</motion.span>
								<motion.img src={arrowRight} />
							</motion.div>
							<motion.div className="hover">
								<motion.span>Mon CV</motion.span>
							</motion.div>
						</motion.a>
					</motion.div>
					<motion.div className="col-right">
						{data &&
							data.skills &&
							data.skills.length > 0 &&
							data.skills.map((skill, k) => (
								<motion.div className="skill" key={k}>
									<motion.span className="name">
										{skill.name}
									</motion.span>
									<motion.div className="value">
										<motion.div
											className="fill"
											style={{
												background: skill.color,
												color: skill.color,
											}}
											initial={{ width: 0 }}
											whileInView={{
												width: skill.value + "%",
												transition: {
													type: "spring",
													duration: 1,
													delay: k / 10 + 0.1,
												},
											}}
											transition={{
												duration: 0,
											}}
										/>
									</motion.div>
								</motion.div>
							))}
					</motion.div>
				</motion.div>
			</SkillsSection>
			<ContactSection id="contact" exit={{ opacity: 0 }}>
				<motion.div>
					<motion.h1>
						Me
						<br />
						contacter
					</motion.h1>
					<ContactForm></ContactForm>
				</motion.div>
			</ContactSection>
		</>
	);
}
