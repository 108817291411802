import styled from "styled-components";

const SCNotFound = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	color: white;
	gap: 10px;

	h1 {
		font-size: 100px;
		font-feature-settings: "pnum" on, "lnum" on;
		text-align: center;
	}

	p {
		font-size: 28px;
	}
`;

export default function NotFound() {
	return (
		<SCNotFound>
			<h1>OOPS</h1>
			<p>On dirait que cette page n'existe pas</p>
		</SCNotFound>
	);
}
