import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import RiveLogo from "../assets/rive/logo.riv";
import RiveMenu from "../assets/rive/menu.riv";
import { useRive, useStateMachineInput } from "rive-react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

const SCNavbar = styled(motion.div)`
	width: 100vw;
	height: 100px;
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	transition: opacity 0.5s ease;

	.logo {
		position: absolute;
		left: 80px;
		top: 80px;
		width: 36px;
		height: 36px;
		pointer-events: all;

		@media (max-width: 568px) {
			left: 50px;
			top: unset;
		}

		div {
			transform: translateX(-16px);
			width: 210px !important;
			height: 36px !important;
			pointer-events: none;

			canvas {
				width: 210px !important;
				height: 36px !important;
			}
		}
	}

	.menu {
		position: absolute;
		right: 80px;
		top: 80px;
		width: 36px;
		height: 36px;
		pointer-events: all;
		cursor: pointer;

		@media (max-width: 568px) {
			right: 50px;
			top: unset;
		}
	}

	.theme {
		position: fixed;
		right: 146px;
		top: 80px;
		width: 36px;
		height: 36px;
		pointer-events: all;
		cursor: pointer;
	}

	.scroll {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		bottom: 50px;
		left: 80px;
		transition: opacity 0.5s ease;

		@keyframes scroll {
			0% {
				height: 40px;
			}
			30% {
				height: 80px;
			}
			100% {
				height: 40px;
			}
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			width: 1px;
			height: 80px;
			background: #ffffff;
			animation: scroll 2s infinite;
		}
	}
`;

const SCMenu = styled(motion.div)`
	position: fixed;
	z-index: -1;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	background: #151515;
	pointer-events: all;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	gap: 20px;

	.inner {
		display: flex;
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
		gap: 20px;
		padding: 0 10px;

		a {
			display: block;
			font-family: "Ilisarniq";
			font-style: normal;
			font-weight: 900;
			font-size: 70px;
			text-transform: uppercase;
			opacity: 0.5;
			transition: opacity 0.2s ease, transform 0.5s ease;
			text-align: center;

			@media (max-width: 568px) {
				font-size: 40px;
			}

			&:hover {
				opacity: 1;
				transform: scaleX(110%);
			}
		}
	}
`;

const Logo = () => {
	const { rive, RiveComponent } = useRive({
		src: RiveLogo,
		stateMachines: "State Machine 1",
		autoplay: true,
	});

	const showInput = useStateMachineInput(rive, "State Machine 1", "Show");

	useEffect(() => {
		if (!showInput) return;
		showInput.value = true;
		setTimeout(() => {
			showInput.value = false;
		}, 3000);
	}, [showInput]);

	return (
		<Link
			to="/"
			className="logo"
			onMouseEnter={() => {
				showInput.value = true;
			}}
			onMouseLeave={() => {
				showInput.value = false;
			}}
		>
			<RiveComponent />
		</Link>
	);
};

const AnimatedLink = ({ to, children, ...props }) => {
	return (
		<motion.span
			initial={{ opacity: 0, y: -20 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{
				opacity: 0,
				x: -200,
				transition: {
					delay: props.delay / 2,
					type: "linear",
					ease: "easeInOut",
				},
			}}
			transition={{
				duration: 0.2,
				delay: props.delay,
				type: "spring",
				stiffness: 100,
				damping: 5,
			}}
		>
			<Link to={to} {...props}>
				{children}
			</Link>
		</motion.span>
	);
};

const AnimatedHashLink = ({ to, children, ...props }) => {
	return (
		<motion.span
			initial={{ opacity: 0, y: -20 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{
				opacity: 0,
				x: -200,
				transition: {
					delay: props.delay / 2,
					type: "linear",
					ease: "easeInOut",
				},
			}}
			transition={{
				duration: 0.2,
				delay: props.delay,
				type: "spring",
				stiffness: 100,
				damping: 5,
			}}
		>
			<HashLink to={to} {...props}>
				{children}
			</HashLink>
		</motion.span>
	);
};

const Menu = () => {
	const { rive, RiveComponent } = useRive({
		src: RiveMenu,
		stateMachines: "State Machine 1",
		autoplay: true,
	});
	const [menuOpen, setMenuOpen] = useState(false);

	const activeInput = useStateMachineInput(rive, "State Machine 1", "Active");

	const handleCloseMenu = () => {
		activeInput.value = !activeInput.value;
		setMenuOpen(!menuOpen);
	};

	return (
		<>
			<div
				className="menu"
				onClick={() => {
					activeInput.value = !activeInput.value;
					setMenuOpen(!menuOpen);
				}}
			>
				<RiveComponent />
			</div>

			<AnimatePresence mode="wait">
				{menuOpen && (
					<SCMenu
						//style={{ pointerEvents: menuOpen ? "all" : "none" }}

						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0, transition: { delay: 1.25 } }}
						transition={{
							duration: 0.5,
						}}
					>
						<motion.div className="inner">
							<AnimatedHashLink
								to="/#home"
								delay={0.5}
								onClick={handleCloseMenu}
							>
								Accueil
							</AnimatedHashLink>
							<AnimatedLink
								to="/projects"
								delay={0.6}
								onClick={handleCloseMenu}
							>
								Projets
							</AnimatedLink>
							<AnimatedHashLink
								to="/#contact"
								delay={0.7}
								onClick={handleCloseMenu}
							>
								Me contacter
							</AnimatedHashLink>
						</motion.div>
					</SCMenu>
				)}
			</AnimatePresence>
		</>
	);
};

function Navbar() {
	// create state that store a boolean value that represents if window is wider than 568px
	const [isMobile] = useState(window.innerWidth < 568);
	const [hideNavbar, setHideNavbar] = useState(false);

	// on scroll to bottom, hide navbar, on scroll to top, show navbar
	useEffect(() => {
		let prevScrollpos = window.pageYOffset;
		window.onscroll = function () {
			let currentScrollPos = window.pageYOffset;
			if (prevScrollpos > currentScrollPos) {
				setHideNavbar(false);
			} else {
				setHideNavbar(true);
			}
			prevScrollpos = currentScrollPos;
		};
	}, []);

	return (
		<SCNavbar
			className="navbar"
			animate={{ top: isMobile ? (hideNavbar ? -100 : 0) : 0 }}
			transition={{ duration: 0.5 }}
			style={{
				backgroundColor: isMobile
					? "rgba(21, 21, 21, 0.75)"
					: "transparent",
				backdropFilter: isMobile ? "blur(10px)" : null,
			}}
		>
			<Logo />
			<Menu />
		</SCNavbar>
	);
}

export default Navbar;
