import styled from "styled-components";

const SCFooter = styled.div`
	width: 100vw;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 14px;
	font-weight: 300;
	line-height: 150%;
`;

const Footer = () => {
	return (
		<SCFooter>
			<p>
				&copy; 2017 - {new Date().getFullYear()} <br />
				<a href="https://avangarde-studio.com">Avangarde Studio</a>.
				Tous droits réservés.
			</p>
		</SCFooter>
	);
};

export default Footer;
