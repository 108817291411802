import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    /* width */
    ::-webkit-scrollbar {
        width: 12px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #1c1c1c; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #fff;
        cursor: grab;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #fff; 
    }

    html{
        background: #151515;
        width: 100vw;
        color: white;
        font-family: 'Raleway', sans-serif;
        overflow-x: hidden;
        font-feature-settings: 'pnum' on, 'lnum' on;
    }
    body{
        
    }

    #root{
        width: 100vw;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    section{
        width: 100vw;
        height: auto;
        min-height: 100vh;
        display:flex;
        justify-content: center;
        align-items: center;

        >div{
            width: 70%;

            @media (max-width: 568px) {
                width: 80%;
            }
        }
    }

    #projects {
        width: 70vw;
        aspect-ratio: 16/9;
        height: auto;
        //max-width: 1200px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        position: relative;

        @media (max-width: 568px) {
            width: 100%;
            aspect-ratio: 9/18;
        }

        > canvas {
            position: absolute;
            z-index: -1;
            height: 110vh;
            aspect-ratio: 16/9;
            max-width: 100vw;
            filter: blur(100px);
            transform: scale(1.2) translateY(-10vh);
            opacity: 0;
            border-radius: 50%;
        }

        .project {
            width: 100%;
            height: 100%;
            box-shadow: 0px 30px 150px rgba(0, 0, 0, 0.5);
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            //overflow: hidden;

            video {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                //transition: all 2s ease-out;
                border-radius: 15px;
                filter: brightness(0.55);

                @media (max-width: 568px) {
                    filter: brightness(0.45);
                }
            }

            .content {
                position: absolute;
                z-index: 1;
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
                position: relative;
                gap: 54px;
                width: 100%;
                height: 100%;
                border-radius: 15px;

                h1 {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: "Ilisarniq";
                    font-style: normal;
                    font-weight: 900;
                    font-size: 70px;
                    line-height: 80px;
                    color: #ffffff;
                    text-align: center;
                    max-width: 50%;
                    text-transform: uppercase;
                    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.5));

                    @media (max-width: 568px) {
                        font-size: 40px;
                        line-height: 50px;
                    }
                }

                .link {
                    cursor: pointer;
                    border: 2px solid #ffffff;
                    border-radius: 50px;
                    padding: 20px 30px;
                    position: relative;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    width: 300px;
                    transition: none;

                    .initial,
                    .hover {
                        pointer-events: none;
                        display: flex;
                        flex-flow: row;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        gap: 10px;
                        width: 100%;
                        height: 100%;
                        transition: all 0.3s ease;

                        span {
                            font-family: "Raleway";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            letter-spacing: 2px;
                            text-transform: uppercase;
                            transform: translateY(1px);
                        }
                    }

                    &:hover {
                        background: white;
                        transition: 0.2s background 0.2s ease;

                        .initial {
                            opacity: 0;
                        }

                        .hover {
                            transform: none;
                        }
                    }

                    .hover {
                        background: white;
                        border-radius: 50px;
                        transform: translateX(100%);

                        span {
                            color: #1c1c1c;
                        }

                        img {
                            filter: brightness(0);
                        }
                    }
                }
            }
        }

        .navigation {
            position: absolute;
            bottom:-50px;
            display: flex;
            flex-flow: row;
            justify-content: center;
            align-items: center;
            gap: 20px;
            margin-top: 10px;

            @media (max-width: 568px) {
                bottom: 50px;
            }

            >div {
                width: 100px;
                height: 4px;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 4px;
                cursor: pointer;
                //overflow: hidden;
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .click{
                    position: absolute;
                    width: 100%;
                    height: 25px;
                }

                &.active{
                    .inner{
                        background: white;
                        position: absolute;
                        width: 0%;
                        height: 100%;
                        animation: 8.3s linear widthProgress;
                        border-radius: 4px;

                        @keyframes widthProgress {
                            from {width: 0%; opacity: 0 }
                            5% {width: 0%; opacity: 1 }
                            95%   { width: 90%; opacity: 1 }
                            to   { width: 100%; opacity: 0 }
                        }
                    }
                }

                
            }

            #allProjects{
                    position: absolute;
                    margin-top: 250px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    width: 350px;
                    border-radius: 50px;

                    a {
                        border: 2px solid #ffffff;
                        border-radius: 50px;
                        padding: 30px 30px;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        width: 350px;

                                .initial,
                                .hover {
                                    display: flex;
                                    flex-flow: row;
                                    justify-content: center;
                                    align-items: center;
                                    position: absolute;
                                    gap: 10px;
                                    width: 100%;
                                    height: 100%;
                                    transition: all 0.3s ease;

                                    span {
                                        font-family: "Raleway";
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        display: flex;
                                        align-items: center;
                                        text-align: center;
                                        letter-spacing: 2px;
                                        text-transform: uppercase;
                                        transform: translateY(1px);
                                    }
                                }

                                &:hover {
                                    .initial {
                                        opacity: 0;
                                    }
                                    .hover {
                                        transform: none;
                                    }
                                }

                                .hover {
                                    background: white;
                                    border-radius: 50px;
                                    transform: translateX(100%);

                                    span {
                                        color: #1c1c1c;
                                    }

                                    img {
                                        filter: brightness(0);
                                    }
                                }
                    }
            }
        }
    }

    #videoPortal{
            position: fixed;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            transition: opacity 0.3s linear;
            display: flex;
            justify-content: center;
            align-items: center;

            video {
                position: absolute;
                width: 70vw;
                //height: 70vh;
                object-fit: cover;
                //transition: all 2s ease-out;
                border-radius: 0 0 15px 15px;
                filter: brightness(0.65);
                //transition: height .5s ease-out;
            }
        }
`;
